<template>
  <div class="online-selling-container">
    <div class="title">{{ content.onlineSellingPoints }}</div>
    <div
      class="grid grid-cols-6 gap-y-10 gap-x-0 md:gap-x-10 mt-6"
      v-if="onlineShops"
    >
      <div
        class="xl:col-span-2 md:col-span-3 col-span-6"
        v-for="shop in onlineShops"
        :key="shop.id"
      >
        <SellingPointsCard :shop="shop" />
      </div>
    </div>
    <div class="my-8 relative"></div>
  </div>
</template>

<script>
// import SellingPointsCard from "/src/components/ui/SellingPoints/SellingPointsCard.vue";
import SellingPointsCard from "../../../components/ui/SellingPoints/SellingPointsCard.vue";

export default {
  inject: ["content"],
  components: {
    SellingPointsCard,
  },
  computed: {
    onlineShops() {
      return this.$store.getters["shops/onlineShops"];
    },
  },
};
</script>